export default function ContextToolbar(selector, inset, slb) {
    var self = null;
    var scrollable = $(slb);
    var elem = $(selector);
    var scrollMemento = 0;
    var inset = inset;
    var height = $(window).width() < 740 ? 72 : 72;
    var extendedFactor = 1;
    this.isCollapsed = true;
    this.isVisible = true;
    var isLoading = false;
    var stub = null;
    var shouldHideWhenScroll = true;
    var gh = null;
    var messageWrapper = null;
    var loaderWrapper = null;

    this.getGeolocationHandler = function() {
        return gh;
    }

    this.setShouldHideWhenScroll = function(should) {
        shouldHideWhenScroll = should;
    };

    this.getElement = function() {
        return elem;
    };

    this.hide = function() {
        var h = "-{height}px".format({height: height + 10});
        if (elem.css('top') != h && self.isVisible) {
            elem.css('top', h);
            elem.addClass("hidden_toolbar");
        }

        self.isVisible = false;
    };

    this.show = function() {
        var h = "-{height}px".format({height: 0});

        if (elem.css('top') != h && !self.isVisible) {
            elem.css('top', h);
            elem.removeClass("hidden_toolbar");
        }

        self.isVisible = true;
    };

    this.extend = function(f) {
        var current = elem.data("current-factor")*1.0;

        var factor = typeof(f) != 'undefined' ? f*1.0 : extendedFactor;

        var h = "{height}px".format({height: $(window).height()*factor});

        if (!self.isCollapsed && (current == factor))
            return;

        self.show();

        elem.data("current-factor", factor);
        elem.addClass("extended");

        self.isCollapsed = false;

        if (elem.css('height') != h)
            elem.css('height', h);

        stub.addClass("active");
        $("body").css("overflow-y", "hidden");
        scrollable.css("overflow-y", "hidden");
    };

    this.collapse = function() {
        var h = "{height}px".format({height: height});

        if (self.isCollapsed)
            return;

        self.isCollapsed = true;

        if (elem.css('height') != h)
            elem.css('height', h);

        elem.removeClass("extended");

        stub.removeClass("active");
        $("body").css("overflow-y", "visible");
        scrollable.css("overflow-y", "visible");
    };

    this.toggleExtend = function(f) {
        var factor = typeof(f) != 'undefined' ? f*1.0 : extendedFactor;

        var current = elem.data("current-factor")*1.0;

        if (self.isCollapsed || (current != factor))
            self.extend(factor);
        else
            self.collapse();
    };

    this.getMessageWrapper = function() {
        return messageWrapper;
    };

    this.getLoaderWrapper = function() {
        return messageWrapper;
    };

    this.showMessage = function(content, action, callback) {
        messageWrapper.find(".toolbar_message").html(content);
        messageWrapper.find(".toolbar_message_action").html(action);
        messageWrapper.find(".toolbar_message_action").click(function() {
            callback(self, messageWrapper);
        });

        messageWrapper.addClass("active");

        return messageWrapper;
    };

    this.hideMessage = function() {
        messageWrapper.removeClass("active");

        return messageWrapper;
    }


    this.setLoading = function(l) {
        isLoading = l;

        if (l)
            loaderWrapper.addClass("active");
        else
            loaderWrapper.removeClass("active");
    };

    this.isLoading = function() {
        return isLoading;
    };

    var __construct = function (that) {
        self = that;

        stub = $(".toolbar_stub");

        messageWrapper = self.getElement().find(".toolbar_message_wrapper");
        loaderWrapper = self.getElement().find(".toolbar_loader");

        self.getElement().find(".inline_holder").removeClass("active");

        stub.click(function() {
            self.collapse();
        });

        self.hide();

        self.getElement().css("height", height);
        self.getElement().find(".toolbar_content").css("height", height);

        scrollable.scroll(function() {
            var scroll = scrollable.scrollTop();
            var offset = scroll - scrollMemento;
            scrollMemento = scroll;

            if (shouldHideWhenScroll) {
                if((offset <= 0)) {
                    self.hide();
                } else {
                    if (scroll > inset) {
                        self.show();
                    } else {
                        self.hide();
                    }
                }
            } else {
                if (scroll > inset) {
                    self.show();
                } else {
                    self.hide();
                }
            }
        });

        if (typeof(GeolocationHandler) != 'undefined')
            gh = new GeolocationHandler();
    }(this)
}

export function Toolbar(selector, inset, inlineWrapper) {
    var self = null;
    var elem = $(selector);
    var scrollMemento = 0;
    var inset = inset;
    var inlineWrapper = $(inlineWrapper);
    var height = 80;
    var extendedFactor = 1;
    this.isCollapsed = true;
    this.isVisible = true;
    this.isInline = false;
    var isLoading = false;
    var stub = null;
    var searchBox = null;
    var listeners = [];
    var geolocationHandler = null;
    var shouldHideWhenScroll = true;
    var shouldBeInlineAtTop = false;
    var messageWrapper = null;
    var loaderWrapper = null;
    var user = null;

    this.setUser = function(u) {
        user = u;
    }

    this.getUser = function() {
        return user;
    }

    this.setShouldHideWhenScroll = function(should) {
        shouldHideWhenScroll = should;
    };

    this.setShouldBeInlineAtTop = function(should) {
        shouldBeInlineAtTop = should;

        var scroll = $(window).scrollTop();
        var offset = scroll - scrollMemento;

        scrollMemento = scroll;

        if (shouldBeInlineAtTop) {
            if(scroll <= 0) {
                self.inline();
            } else {
                if (scroll > inset) {
                    self.fixed();
                } else {
                    self.inline();
                }
            }
        }
    };

    this.decorate = function (value) {
        return value;
    };

    this.getElement = function() {
        return elem;
    };

    this.getMessageWrapper = function() {
        return messageWrapper;
    };

    this.showMessage = function(content, action, callback) {
        messageWrapper.find(".toolbar_message").html(content);
        messageWrapper.find(".toolbar_message_action").html(action);
        messageWrapper.find(".toolbar_message_action").click(function() {
            callback(self, messageWrapper);
        });

        messageWrapper.addClass("active");

        return messageWrapper;
    };

    this.hideMessage = function() {
        messageWrapper.removeClass("active");

        return messageWrapper;
    }

    this.setLoading = function(l) {
        isLoading = l;

        if (l)
            loaderWrapper.addClass("active");
        else
            loaderWrapper.removeClass("active");
    };

    this.isLoading = function() {
        return isLoading;
    };

    this.getGeolocationHandler = function() {
        return geolocationHandler;
    };

    this.hide = function() {
        var h = "-{height}px".format({height: height + 10});
        if (elem.css('top') != h && self.isVisible)
            elem.css('top', h);

        self.isVisible = false;
    };

    this.show = function() {
        var h = "-{height}px".format({height: 0});

        if (elem.css('top') != h && !self.isVisible)
            elem.css('top', h);

        self.isVisible = true;
    };

    this.extend = function(f) {
        var current = elem.data("current-factor")*1.0;

        var factor = typeof(f) != 'undefined' ? f*1.0 : extendedFactor

        var h = "{height}px".format({height: $(window).height()*factor});

        if (!self.isCollapsed && (current == factor))
            return;

        self.show();

        elem.data("current-factor", factor);

        self.isCollapsed = false;

        if (elem.css('height') != h)
            elem.css('height', h);

        stub.addClass("active");
        $("body").css("overflow-y", "hidden");
    };

    this.collapse = function() {
        var h = "{height}px".format({height: height});

        if (self.isCollapsed)
            return;

        self.isCollapsed = true;

        if (elem.css('height') != h)
            elem.css('height', h);

        stub.removeClass("active");
        $("body").css("overflow-y", "visible");
    };

    this.toggleExtend = function(f) {
        var factor = typeof(f) != 'undefined' ? f*1.0 : extendedFactor

        var current = elem.data("current-factor")*1.0;

        if (self.isCollapsed || (current != factor))
            self.extend(factor);
        else
            self.collapse();
    };

    this.inline = function() {
        if (self.isInline)
            return;

        self.getElement().addClass("inline_toolbar");

        if (inlineWrapper.length > 0)
            inlineWrapper.attr("style", "");

        self.isInline = true;
    };

    this.fixed = function() {
        if (!self.isInline)
            return;

        self.getElement().removeClass("inline_toolbar");

        if (inlineWrapper.length > 0)
            inlineWrapper.attr("style", "padding-top: 58px !important");


        self.isInline = false;
    };

    var __construct = function (that) {
        self = that;

        stub = $(".toolbar_stub");
        messageWrapper = self.getElement().find(".toolbar_message_wrapper");
        loaderWrapper = self.getElement().find(".toolbar_loader");
        self.getElement().find(".inline_holder").hide();

        stub.click(function() {
            self.collapse();
        });

        self.hide();

        var ww = $(window).width();

        self.getElement().css("height", height);
        self.getElement().find(".toolbar_content").css("height", height);

        if (isMobile.any()) {
            self.getElement().find(".search-wrapper").addClass("mobile_mode");
        } else {
            self.getElement().find(".search-wrapper").removeClass("mobile_mode");
        }

        $(window).scroll(function() {
            var scroll = $(window).scrollTop();
            var offset = scroll - scrollMemento;

            scrollMemento = scroll;

            if (shouldHideWhenScroll) {
                if((offset <= 0)) {
                    self.hide();
                } else {
                    if (scroll > inset) {
                        self.show();
                    } else {
                        self.hide();
                    }
                }
            } else {
                if (scroll > inset) {
                    if (!!searchBar && searchBar.isCollapsed)
                        self.show();
                } else {
                    if (!!searchBar && searchBar.isCollapsed)
                        self.hide();
                }
            }

            if (shouldBeInlineAtTop) {
                if(scroll <= 0) {
                    self.inline();
                } else {
                    if (scroll > inset) {
                        self.fixed();
                    } else {
                        self.inline();
                    }
                }
            }
        });

        if (typeof(GeolocationHandler) != 'undefined')
            geolocationHandler = new GeolocationHandler();
    }(this)
}

export function SearchBar(selector, inset) {
    var self = null;
    var elem = $(selector);
    var inset = inset;
    var scrollMemento = 0;
    this.isCollapsed = false;
    this.isActive = true;
    this.isSearching = false;
    this.isFullscreen = true;
    var geolocationHandler = null;

    var close = null;
    var extension = null;
    var holder = null;
    var component = null;
    var lastQuery = "";

    this.decryptQuery = function(q) {
        return q.replace(/\-/g, " ").replace(/~/g, "-");
    }

    this.encryptQuery = function(q) {
        return q.replace(/\-/g,"~").replace(/ /g,"-").replace(/\\\//g, "-").replace(/\/\//g, "-").toLocaleLowerCase();
    }

    var onSearchCompleteHandler = function (bar, sender, query) {
    };

    this.setOnSearchCompleteHandler = function(handler) {
        onSearchCompleteHandler = handler;
    }

    this.decorate = function (value) {
        return value;
    };

    this.getElement = function() {
        return elem;
    }

    this.getGeolocationHandler = function() {
        return geolocationHandler;
    }

    this.go = function(query) {
        var url = "<%= MRQ::Application.routes.url_helpers.pub_start_index_path %>/{q}".format({q: self.encryptQuery(query)});

        goForwardSafely(url);
    };

    this.extend = function() {
        self.isCollapsed = false;

        extension.addClass("active");
        extension.show();

        $("body, html").css("overflow-y", "hidden");
        $("body, html").css("background", "#fafafa");
    }

    this.collapse = function() {
        if (self.isCollapsed)
            return;

        self.isCollapsed = true;

        extension.hide();
        extension.removeClass("active");

        $("body, html").css("overflow-y", "initial");
        $("body, html").css("background", "initial");
    }

    this.toggleExtend = function() {
        if (self.isCollapsed)
            self.extend();
        else
            self.collapse();
    }

    this.getComponent = function() {
        return component;
    }

    this.getLastQuery = function() {
        return lastQuery;
    }

    this.setQuery = function(q) {
        lastQuery = q;

        extension.find("input.context_toolbar_search_input").val(q).trigger('change');
        elem.find("input.context_toolbar_search_input").val(q).trigger('change');
    }

    this.search = function(q) {
        lastQuery = q;
        component.getProxy().execute();
    }

    this.clear = function() {
        var input = elem.find("input");

        if (!self.isSearching) {
            var emptyMessage = "busque por serviços...";
            component.clear();
            component.setEmptyMessage(emptyMessage);
            component.setEmpty(true);
        }

        input.val("");
        input.trigger("change");
    }

    var initializeContent = function() {
        var searchProxy = new CancellableAsyncViewProxy({
            success: function(proxy) {
                self.isSearching = false;
            },
            failure: function() {
                self.isSearching = false;
            },
            prepare: function(proxy, info) {
                var bar = proxy.getContainer().getData().searchBar;
                var query = bar.getLastQuery();

                info.url = "<%= MRQ::Application.routes.url_helpers.pub_start_index_path %>.js";
                info.data = { query: bar.getLastQuery(), target: proxy.getContainer().getUniqueIdentifier() };

                var pendingMessage = "buscando resultados gerais";
                var emptyMessage = "faça uma nova busca";
                if (!checkIfEmpty(query)) {
                    pendingMessage = "buscando resultados para <b>{q}</b>..".format({q: query});
                    emptyMessage = "sua pesquisa por <b>{q}</b> não encontrou nenhum resultado".format({q: query});
                }

                bar.getComponent().setPendingMessage(pendingMessage);
                bar.getComponent().setEmptyMessage(emptyMessage);

                self.isSearching = true;

                return info;
            }
        });

        var searchBuilder = new CollectionContainerBuilder({
            build: function (container, ele) {
                return ele;
            },
            configure: function (container, ele) {
                var ws = $(".tag_widget").not(".tag_widget.natural_tag_widget");

                ws.click(function() {
                    var w = $(this);

                    elem.find("input.context_toolbar_search_input").val(w.data("title"));
                    elem.find("input.context_toolbar_search_input").trigger('change');

                    extension.find("input.context_toolbar_search_input").val(w.data("title"));
                    extension.find("input.context_toolbar_search_input").trigger('change');

                    onSearchCompleteHandler(self, w, w.data("title"));

                    self.collapse();
                });

                container.setLoading(false);
                return ele;
            }
        }, {
            widgetSelector: ".tag_widget",
            wrapperSelector: ".tag_widget_wrapper",
            isMini: false,
            aspectWidth: 9,
            aspectHeight: 9
        });
        searchBuilder.getParams().loaderColor = "#444";

        component = new ContainerComponent({
            identifier: "tag_search_container",
            proxy: searchProxy,
            builder: searchBuilder,
            containerSelector: ".collection-wrapper",
            contentSelector: ".collection"
        });
        component.getData().searchBar = self;
        component.setPendingMessage("buscando..");
        component.setEmptyMessage("busque serviços..");
        component.setFailureMessage("ops, houve uma falha ao coletar as informações");
        component.create();

        holder = new HolderComponent({
            identifier: "search_holder",
            builder: new HolderBuilder({
                build: function(holder) {
                    return $(holder.getContainerSelector());
                }
            }),
            containerSelector: ".search.holder",
            contentSelector: ".result-wrapper"
        });
        holder.create();

        holder.addContainer(component);
        component.setLoading(false);
    }

    var toolbarStateMemento  = null;

    var onClickAction = function() {
        if (self.isCollapsed) {
            if (isMobile.any() && toolbar.isVisible) {
                toolbar.collapse();
                toolbar.hide();
                toolbarStateMemento = true;
            }

            self.extend();
        } else {
            if (isMobile.any() && (toolbarStateMemento == true)) {
                toolbar.collapse();
                toolbar.show();
                toolbarStateMemento = null;
            }

            self.collapse();
        }
    };

    this.performClickAction = function() {
        onClickAction();
    };

    var initializeQueryObserver = function(self) {
        elem.on("click", onClickAction);

        extension.find("input.context_toolbar_search_input").on("keyup", function(event) {
            if (event.keyCode == 13) {
                onSearchCompleteHandler(self, $(this), $(this).val());

                $(this).blur();

                self.collapse();
            } else {
                self.search($(this).val());
            }
        });

        elem.find("input.context_toolbar_search_input").on("keyup", function(event) {
            if (event.keyCode == 13) {
                onSearchCompleteHandler(self, $(this), $(this).val());

                $(this).blur();

                self.collapse();
            } else {
                self.search($(this).val());
            }
        });
    }

    var base = null;

    var __construct = function (that) {
        self = that;

        extension = $("#search-box");
        close = extension.find(".close_action");

        if (isMobile.any()) {
            extension.addClass("mobile_mode");
        } else {
            extension.removeClass("mobile_mode");
        }

        initializeContent(self);
        initializeQueryObserver(self);

        self.collapse();

        close.click(function() {
            if (isMobile.any() && (toolbarStateMemento == true)) {
                toolbar.show();
                toolbarStateMemento = null;
            }

            self.collapse();
        });

        self.search();

        if (typeof(GeolocationHandler) != 'undefined')
            geolocationHandler = new GeolocationHandler();
    }(this)
}

var searchBar = null;
var toolbar = null;