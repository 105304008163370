console.group("inicializando o pack de imagens do app")



import '/var/www/apps/mrq/app/frontend/images/brand/150/MARQUESBRANDINGCO LOGO ID_DESTAQUES.png';

import '/var/www/apps/mrq/app/frontend/images/brand/150/MARQUESBRANDINGCO LOGO MARQUESBRANDING_DESTAQUES.png';

import '/var/www/apps/mrq/app/frontend/images/brand/150/MARQUESBRANDINGCO LOGO ORIGINAL GOOGLE.png';

import '/var/www/apps/mrq/app/frontend/images/brand/150/MARQUESBRANDINGCO LOGO ORIGINAL X.png';

import '/var/www/apps/mrq/app/frontend/images/brand/150/MARQUESBRANDINGCO LOGO ORIGINAL.png';

import '/var/www/apps/mrq/app/frontend/images/brand/150/MARQUESBRANDINGCO LOGO SOCIALMEDIA_DESTAQUES.png';

import '/var/www/apps/mrq/app/frontend/images/brand/150/MARQUESBRANDINGCO LOGO VERTICAL W.png';

import '/var/www/apps/mrq/app/frontend/images/brand/150/MARQUESBRANDINGCO LOGO VERTICAL X.png';

import '/var/www/apps/mrq/app/frontend/images/brand/150/MARQUESBRANDINGCO LOGO VERTICAL.png';

import '/var/www/apps/mrq/app/frontend/images/brand/150/MARQUESBRANDINGCO LOGO X.png';

import '/var/www/apps/mrq/app/frontend/images/brand/200/MARQUESBRANDINGCO LOGO ID_DESTAQUES.png';

import '/var/www/apps/mrq/app/frontend/images/brand/200/MARQUESBRANDINGCO LOGO MARQUESBRANDING_DESTAQUES.png';

import '/var/www/apps/mrq/app/frontend/images/brand/200/MARQUESBRANDINGCO LOGO ORIGINAL GOOGLE.png';

import '/var/www/apps/mrq/app/frontend/images/brand/200/MARQUESBRANDINGCO LOGO ORIGINAL X.png';

import '/var/www/apps/mrq/app/frontend/images/brand/200/MARQUESBRANDINGCO LOGO ORIGINAL.png';

import '/var/www/apps/mrq/app/frontend/images/brand/200/MARQUESBRANDINGCO LOGO SOCIALMEDIA_DESTAQUES.png';

import '/var/www/apps/mrq/app/frontend/images/brand/200/MARQUESBRANDINGCO LOGO VERTICAL W.png';

import '/var/www/apps/mrq/app/frontend/images/brand/200/MARQUESBRANDINGCO LOGO VERTICAL X.png';

import '/var/www/apps/mrq/app/frontend/images/brand/200/MARQUESBRANDINGCO LOGO VERTICAL.png';

import '/var/www/apps/mrq/app/frontend/images/brand/200/MARQUESBRANDINGCO LOGO X.png';

import '/var/www/apps/mrq/app/frontend/images/brand/400/MARQUESBRANDINGCO LOGO ID_DESTAQUES.png';

import '/var/www/apps/mrq/app/frontend/images/brand/400/MARQUESBRANDINGCO LOGO MARQUESBRANDING_DESTAQUES.png';

import '/var/www/apps/mrq/app/frontend/images/brand/400/MARQUESBRANDINGCO LOGO ORIGINAL GOOGLE.png';

import '/var/www/apps/mrq/app/frontend/images/brand/400/MARQUESBRANDINGCO LOGO ORIGINAL X.png';

import '/var/www/apps/mrq/app/frontend/images/brand/400/MARQUESBRANDINGCO LOGO ORIGINAL.png';

import '/var/www/apps/mrq/app/frontend/images/brand/400/MARQUESBRANDINGCO LOGO SOCIALMEDIA_DESTAQUES.png';

import '/var/www/apps/mrq/app/frontend/images/brand/400/MARQUESBRANDINGCO LOGO VERTICAL W.png';

import '/var/www/apps/mrq/app/frontend/images/brand/400/MARQUESBRANDINGCO LOGO VERTICAL X.png';

import '/var/www/apps/mrq/app/frontend/images/brand/400/MARQUESBRANDINGCO LOGO VERTICAL.png';

import '/var/www/apps/mrq/app/frontend/images/brand/400/MARQUESBRANDINGCO LOGO X.png';

import '/var/www/apps/mrq/app/frontend/images/brand/app_logo_250.png';

import '/var/www/apps/mrq/app/frontend/images/brand/app_logo_400.png';

import '/var/www/apps/mrq/app/frontend/images/brand/app_logo_full.png';

import '/var/www/apps/mrq/app/frontend/images/brand/app_logo_full_original.png';

import '/var/www/apps/mrq/app/frontend/images/brand/app_logo_white.png';

import '/var/www/apps/mrq/app/frontend/images/brand/app_logo_white_text.png';

import '/var/www/apps/mrq/app/frontend/images/brand/app_symbol.png';

import '/var/www/apps/mrq/app/frontend/images/brand/app_symbol_250.png';

import '/var/www/apps/mrq/app/frontend/images/brand/app_symbol_400.png';

import '/var/www/apps/mrq/app/frontend/images/brand/app_text.png';

import '/var/www/apps/mrq/app/frontend/images/brand/app_text_150.png';

import '/var/www/apps/mrq/app/frontend/images/brand/app_text_250.png';

import '/var/www/apps/mrq/app/frontend/images/brand/app_text_400.png';

import '/var/www/apps/mrq/app/frontend/images/brand/original/MARQUESBRANDINGCO LOGO ID_DESTAQUES.png';

import '/var/www/apps/mrq/app/frontend/images/brand/original/MARQUESBRANDINGCO LOGO MARQUESBRANDING_DESTAQUES.png';

import '/var/www/apps/mrq/app/frontend/images/brand/original/MARQUESBRANDINGCO LOGO ORIGINAL GOOGLE.png';

import '/var/www/apps/mrq/app/frontend/images/brand/original/MARQUESBRANDINGCO LOGO ORIGINAL X.png';

import '/var/www/apps/mrq/app/frontend/images/brand/original/MARQUESBRANDINGCO LOGO ORIGINAL.png';

import '/var/www/apps/mrq/app/frontend/images/brand/original/MARQUESBRANDINGCO LOGO SOCIALMEDIA_DESTAQUES.png';

import '/var/www/apps/mrq/app/frontend/images/brand/original/MARQUESBRANDINGCO LOGO VERTICAL W.png';

import '/var/www/apps/mrq/app/frontend/images/brand/original/MARQUESBRANDINGCO LOGO VERTICAL X.png';

import '/var/www/apps/mrq/app/frontend/images/brand/original/MARQUESBRANDINGCO LOGO VERTICAL.png';

import '/var/www/apps/mrq/app/frontend/images/brand/original/MARQUESBRANDINGCO LOGO X.png';

import '/var/www/apps/mrq/app/frontend/images/empty_image_large.png';

import '/var/www/apps/mrq/app/frontend/images/pub/header_bkg.jpg';

import '/var/www/apps/mrq/app/frontend/images/pub/loginbkg.png';

import '/var/www/apps/mrq/app/frontend/images/site/152-01.svg';

import '/var/www/apps/mrq/app/frontend/images/site/estrada1.jpg';

import '/var/www/apps/mrq/app/frontend/images/site/estrada2.jpg';

import '/var/www/apps/mrq/app/frontend/images/site/estrada3.jpg';

import '/var/www/apps/mrq/app/frontend/images/site/img_main.png';

import '/var/www/apps/mrq/app/frontend/images/site/junior-girl-reading-book-in-studio.jpg';

import '/var/www/apps/mrq/app/frontend/images/site/main_image1.jpg';

import '/var/www/apps/mrq/app/frontend/images/site/main_image2.jpg';

import '/var/www/apps/mrq/app/frontend/images/site/main_image3.jpg';

import '/var/www/apps/mrq/app/frontend/images/site/main_image4.jpg';


console.groupEnd();
